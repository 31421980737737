export default {
  items: [
    {
      title: true,
      name: 'System'
    },
    {
      name: 'Templates',
      url: '/product/templates',
      icon: 'fa fa-puzzle-piece',
    },
    {
      name: 'Data points',
      url: '/product/data-points',
      icon: 'fa fa-table',
    },
    {
      name: 'Unique Attributes',
      url: '/product/uniqueattributes',
      icon: 'fa fa-tags',
    },
    {
      name: 'Unique Values',
      url: '/system/unique-values/',
      icon: 'fa fa-border-all',
    },
    {
      name: 'Manual input hints',
      url: '/manual-input-hints',
      icon: 'fa fa-hand-paper',
    },
    {
      name: 'Input',
      icon: 'fas fa-cloud-upload-alt',
      children: [
        {
          name: 'Templates',
          url: '/input/templates'
        },
        {
          name: 'Flows',
          url: '/input/input-flows'
        },
        {
          name: 'Ingested files',
          url: '/system/ingested-files',
        },
        {
          name: 'Channels',
          url: '/system/ingestion-channels',
        },
        {
          name: 'Direct outputs',
          url: '/system/direct-outputs',
        },
        {
          name: 'Data pulls',
          url: '/system/data-pulls',
        },
        {
          name: 'Webhooks',
          url: '/system/webhooks',
        }
      ]
    },
    {
      name: 'Output',
      icon: 'fas fa-cloud-download-alt',
      children: [
        {
          name: 'Templates',
          url: '/retailer/integrations'
        },
        {
          name: 'DDTs',
          url: '/retailer/templates'
        },
        {
          name: 'Google Drive connection',
          url: '/system/integrations/google'
        },
        {
          name: 'Magasin FTP Image processing',
          url: '/system/magasin-ftp-images',
        }
      ]
    },
    {
      name: 'Pipelines',
      url: '/pipelines',
      icon: 'fa fa-hand-paper',
    },
    {
      name: 'EDI',
      icon: 'fa fa-envelope',
      children: [
        {
          name: 'Overview',
          url: '/edi'
        },
        {
          name: 'Configuration',
          url: '/edi/configuration'
        },
        {
          name: 'Messages',
          url: '/edi/messages'
        }
      ]
    },
    {
      name: "Amazon",
      icon: 'fa-brands fa-amazon',
      children: [
        {
          name: 'Mapping',
          url: '/system/amazon/marketplaces',
        },
      ]
    },
    {
      name: "Bol.com",
      icon: null,
      children: [
        {
          name: 'Mapping',
          url: '/system/bol/marketplaces',
        },
      ]
    },
    {
      title: true,
      name: 'Companies',
      wrapper: {            // optional wrapper object
        element: '',        // required valid HTML5 element tag
        attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: ''             // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: 'Companies',
      url: '/companies',
      icon: 'fas fa-building',
    },
    {
      title: true,
      name: 'Brands',
      wrapper: {            // optional wrapper object
        element: '',        // required valid HTML5 element tag
        attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: ''             // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: 'Brands',
      url: '/brands',
      icon: 'fas fa-tshirt',
    },
    {
      name: 'Products',
      url: '/product/catalog',
      icon: 'fas fa-book-open',
    },
    {
      title: true,
      name: 'Retailers',
      wrapper: {            // optional wrapper object
        element: '',        // required valid HTML5 element tag
        attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: ''             // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: "Retailers",
      url: "/retailers",
      icon: 'fa fa-store-alt',
    },
    {
      title: true,
      name: 'Users overview',
    },
    {
      name: "Brands",
      url: "/overview/brands",
      icon: 'fa fa-industry',
    },
    {
      name: "Retailers",
      url: "/overview/retailers",
      icon: 'fa fa-store',
    },
    {
      title: true,
      name: 'Others',
    },
    {
      name: 'Orders',
      icon: 'fa fa-envelope',
      children: [
        {
          url: '/orders',
          name: 'Data'
        },
        {
          url: '/order-sharing-policies',
          name: 'Sharing policies'
        }
      ],
    },
    {
      name: 'OC parsers',
      url: '/parsers',
      icon: 'fa fa-clipboard-list'
    },
  ],
};
