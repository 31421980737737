import React, {useEffect, useState} from "react";
import {Form, FormGroup} from "reactstrap";
import {DefaultInput} from "../../../atoms/Input";
import GrayModal from "../../../dataDisplay/GrayModal";
import {ModalErrorAlert} from "../../../commons/errors";
import {noop} from "../../../commons/misc";
import styles from './LoginModal.module.scss'
import addClassNames from "../../../classNameUtils";
import {useHistory} from "react-router";

export const loginFields = {
  email: 'Email',
  password: 'Password'
}

type LoginModalProps = {
  onSubmit: (val: { email: string, password: string }) => void,
  errors: (string | JSX.Element)[] | null,
  loading: boolean,
  badFields: string[],
}

function LoginModal({
                      onSubmit,
                      errors,
                      loading,
                      badFields,
                    }: LoginModalProps) {
  const [isFirstFieldOnFocus, setIsFirstFieldOnFocus] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory()

  useEffect(() => {
    setIsFirstFieldOnFocus(true)
  }, []);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit({email: email, password: password})
  }

  const isFormComplete = () => {
    return email && password;
  }

  const getInputClassName = (inputField: string) => {
    return addClassNames([
      {
        className: styles.input,
        condition: true
      },
      {
        className: styles.errorField,
        condition: Array.isArray(badFields) && !!badFields.find(field => field === inputField)
      }
    ])
  }

  return <GrayModal
    isOpen={true}
    toggle={noop}
    style={{minWidth: "34rem"}}
    title={"Sign in"}
    primaryHeaderButton={{
      text: "Sign in",
      isDisabled: !isFormComplete() || loading,
      dataTestId: "login-button-sign-in",
      onClick: handleSubmit
    }}
    secondaryHeaderButton={{
      text: "Cancel",
      isDisabled: loading,
      dataTestId: "login-button-cancel",
      onClick: () => {
        window.location.href = "https://vuuh.com"
      },
    }}
    bodyContent={
      <div className={styles.container}>
        <Form onSubmit={handleSubmit} action={''} method={'post'} className={styles.form}>
          <FormGroup className={"d-flex align-items-center m-0"}>
            <label className={styles.labelsText}>{loginFields.email}</label>
            <DefaultInput
              id={"email-input"}
              name={'email-input'}
              type="email"
              className={getInputClassName(loginFields.email)}
              onChange={(event) => {
                setEmail(event.target.value)
              }}
              value={email}
              setFocus={isFirstFieldOnFocus}
              onBlur={() => setIsFirstFieldOnFocus(false)}
              placeholder="Type in email"/>
          </FormGroup>
          <FormGroup className={"d-flex align-items-center m-0"}>
            <label className={styles.labelsText}>{loginFields.password}</label>
            <DefaultInput
              id={"password-input"}
              name={'password-input'}
              type="password"
              className={getInputClassName(loginFields.password)}
              onChange={(event) => {
                setPassword(event.target.value)
              }}
              value={password}
              placeholder="Type in password"/>
            <p className={styles.cantRememberText}
               onClick={() => history.push("/forgot-password")}>
              Can't remember
            </p>
          </FormGroup>
          <button className={'d-none'} type={'submit'}/>
        </Form>
        {!!errors?.length && <>
          <div className={styles.separator}/>
          <ModalErrorAlert error={errors[0]} />
        </>}
      </div>
    }/>
}

export default LoginModal;
