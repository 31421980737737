import React, {ReactNode} from 'react';
import GrayModal from '../../../../../ui-kit/src/dataDisplay/GrayModal';
import {graphql, QueryRenderer} from "react-relay";
import environment from "../../../../../Environment";
import {ErrorAlert} from '../../../../../ui-kit/src/commons/errors';
import Loading from '../../../../../ui-kit/src/atoms/Loading';
import {Badge, Table} from 'reactstrap';
import {ImagesModalQuery} from './__generated__/ImagesModalQuery.graphql';
import Pill from '../../../../../ui-kit/src/atoms/Pill';
import ProductImagePlaceholder from '../../../../../ui-kit/src/molecules/products/ProductImagePlaceholder';
import {ourToast} from "../../../../../ui-kit/src/atoms/Toast";
import GrayTableHeader, {GrayTableHeaderCell} from "../../../../../ui-kit/src/molecules/tables/GrayTableHeader";
import SimpleTableRow, {SimpleTableCell} from "../../../../../ui-kit/src/molecules/tables/SimpleTableRow";
import styles from "./ImagesModal.module.scss"


const query = graphql`
    query ImagesModalQuery($unifiedId: ID!) {
        getUnifiedProduct(id: $unifiedId) {
            id
            imageGroups {
                edges {
                    node {
                        id
                        styleNumber
                        colorCode
                        colorName
                        orientation
                        images {
                            edges {
                                node {
                                    id
                                    url
                                    orientation
                                    sourceUrl
                                    hiResUrl
                                    lastModified
                                    image
                                    thumbnailSmall
                                    thumbnailMedium
                                    sizeCategory
                                    type
                                    tags
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`


function ImageUrl({href, withCopy = false, className, children}: {
  href: string | null,
  withCopy?: boolean,
  className?: string,
  children: ReactNode
}) {
  if (href) {
    const onCopy = () => {
      if (typeof href === 'string') {
        navigator.clipboard.writeText(href);
        ourToast("success", "Copied!");
      } else {
        ourToast("error", "Failed to copy!")
      }
    }

    return <div className={className}>
      <a href={href} target={'_blank'}>{children}</a>
      {withCopy && <i className={'fa-light fa-copy ml-2'} style={{cursor: "pointer"}} onClick={onCopy}
                      title={"Copy to clipboard"}/>}
    </div>
  } else {
    return <div className={`text-muted cursor ${className}`}>
      {children}
    </div>
  }
}

type ImagesModalProps = {
  unifiedId: string,
  isOpen: boolean,
  toggle: () => void
}

export default function ImagesModal({unifiedId, isOpen, toggle}: ImagesModalProps) {
  return <GrayModal
    isOpen={isOpen}
    toggle={toggle}
    style={{minWidth: "90rem"}}
    title={'Images'}
    secondaryHeaderButton={{
      text: 'Close',
      onClick: toggle
    }}
    bodyContent={
      <QueryRenderer<ImagesModalQuery>
        environment={environment}
        variables={{unifiedId: unifiedId}}
        query={query}
        render={({error, props}) => {
          if (error) {
            return <ErrorAlert error={error}/>
          }

          if (props) {
            if (props.getUnifiedProduct?.imageGroups.edges.length === 0) {
              return <ErrorAlert error={"No images found, add some first!"}/>;
            }

            return <div>
              {props.getUnifiedProduct?.imageGroups.edges.map(edge => {
                if (!edge?.node) {
                  return null
                }

                let n = edge.node;
                return <div key={n.id}>
                  <hr style={{borderWidth: '8px', margin: 0}}/>
                  <div className="d-flex my-3">
                    <Pill className={'mx-1'}>Style number "{n.styleNumber}"</Pill>
                    <Pill className={'mx-1'}>Color name "{n.colorName}"</Pill>
                    <Pill className={'mx-1'}>Color code "{n.colorCode}"</Pill>
                    <Pill className={'mx-1'}>Orientation
                      <Badge color={'primary'} className={"ml-1"}>{n.orientation}</Badge>
                    </Pill>
                  </div>

                  <Table className={"mb-0"}>
                    <GrayTableHeader className={'d-flex'}>
                      <GrayTableHeaderCell width={10}/>
                      <GrayTableHeaderCell width={10}>
                        Image URLs
                      </GrayTableHeaderCell>
                      <GrayTableHeaderCell width={10}>
                        Thumbnails
                      </GrayTableHeaderCell>
                      <GrayTableHeaderCell width={15}>
                        Last modified
                      </GrayTableHeaderCell>
                      <GrayTableHeaderCell width={10}>
                        Source orientation
                      </GrayTableHeaderCell>
                      <GrayTableHeaderCell width={10}>
                        Size category
                      </GrayTableHeaderCell>
                      <GrayTableHeaderCell width={10}>
                        Type
                      </GrayTableHeaderCell>
                      <GrayTableHeaderCell width={10}>
                        Tags
                      </GrayTableHeaderCell>
                    </GrayTableHeader>
                    <tbody>
                    {n.images.edges.map(edge => {
                      if (!edge?.node) {
                        return null;
                      }

                      let img = edge.node;
                      const lastModified = typeof img.lastModified === "string" ? (
                        new Date(img.lastModified).toLocaleString("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          hour12: false,
                        })
                      ) : "None";

                      return <SimpleTableRow className={styles.row}>
                        <SimpleTableCell width={10} className={"d-flex align-items-center"}>
                          {img.thumbnailMedium ? (
                            <div className={styles.image}>
                              <img
                                src={img.thumbnailMedium}
                                alt={"Product"}/>
                            </div>
                          ) : <ProductImagePlaceholder size={'mdLg'}/>}
                        </SimpleTableCell>
                        <SimpleTableCell width={10} className={"d-flex flex-column justify-content-center"}>
                          <ImageUrl href={img.hiResUrl} withCopy>Hi-res URL</ImageUrl>
                          <ImageUrl href={img.sourceUrl} withCopy className={"mt-2"}>Source URL</ImageUrl>
                          <ImageUrl href={img.url} withCopy className={"mt-2"}>Image URL</ImageUrl>
                        </SimpleTableCell>
                        <SimpleTableCell width={10} className={"d-flex flex-column justify-content-center"}>
                          <ImageUrl href={img.thumbnailSmall} withCopy>Small</ImageUrl>
                          <ImageUrl href={img.thumbnailMedium} withCopy className={"mt-2"}>Medium</ImageUrl>
                        </SimpleTableCell>
                        <SimpleTableCell width={15} className={"d-flex align-items-center"}>
                          {lastModified}
                        </SimpleTableCell>
                        <SimpleTableCell width={10} className={"d-flex align-items-center"}>
                          <Badge color={'info'}>{img.orientation}</Badge>
                        </SimpleTableCell>
                        <SimpleTableCell width={10} className={"d-flex align-items-center"}>
                          <Badge color={'secondary'}>{img.sizeCategory}</Badge>
                        </SimpleTableCell>
                        <SimpleTableCell width={10} className={"d-flex align-items-center"}>
                          <Badge color={'primary'}>{img.type}</Badge>
                        </SimpleTableCell>
                        <SimpleTableCell width={20} className={"d-flex align-items-center"}>
                          <div className={"d-flex h-100 m-0"}>
                              <pre style={{fontSize: '12px', margin: "0", maxHeight: "-webkit-fill-available"}}>
                                {JSON.stringify(JSON.parse(img.tags as string), null, 2)}
                              </pre>
                          </div>
                        </SimpleTableCell>
                      </SimpleTableRow>
                    })}
                    </tbody>
                  </Table>
                </div>
              })}
            </div>
          }

          return <Loading/>
        }}/>
    }
  />
}