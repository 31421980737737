/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type UnifiedProductImageOrientation = "BACK" | "FRONT" | "OTHER" | "SIDE" | "%future added value";
export type UnifiedProductImageSizeCategory = "ORIGINAL" | "OTHER" | "PREVIEW" | "THUMBNAIL" | "%future added value";
export type UnifiedProductImageType = "DETAIL" | "MODEL" | "OTHER" | "PACK" | "%future added value";
export type ImagesModalQueryVariables = {
    unifiedId: string;
};
export type ImagesModalQueryResponse = {
    readonly getUnifiedProduct: {
        readonly id: string;
        readonly imageGroups: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly styleNumber: string | null;
                    readonly colorCode: string | null;
                    readonly colorName: string | null;
                    readonly orientation: string | null;
                    readonly images: {
                        readonly edges: ReadonlyArray<{
                            readonly node: {
                                readonly id: string;
                                readonly url: string | null;
                                readonly orientation: UnifiedProductImageOrientation | null;
                                readonly sourceUrl: string | null;
                                readonly hiResUrl: string | null;
                                readonly lastModified: unknown | null;
                                readonly image: string | null;
                                readonly thumbnailSmall: string | null;
                                readonly thumbnailMedium: string | null;
                                readonly sizeCategory: UnifiedProductImageSizeCategory;
                                readonly type: UnifiedProductImageType;
                                readonly tags: unknown;
                            } | null;
                        } | null>;
                    };
                } | null;
            } | null>;
        };
    } | null;
};
export type ImagesModalQuery = {
    readonly response: ImagesModalQueryResponse;
    readonly variables: ImagesModalQueryVariables;
};



/*
query ImagesModalQuery(
  $unifiedId: ID!
) {
  getUnifiedProduct(id: $unifiedId) {
    id
    imageGroups {
      edges {
        node {
          id
          styleNumber
          colorCode
          colorName
          orientation
          images {
            edges {
              node {
                id
                url
                orientation
                sourceUrl
                hiResUrl
                lastModified
                image
                thumbnailSmall
                thumbnailMedium
                sizeCategory
                type
                tags
              }
            }
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "unifiedId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "orientation",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "unifiedId"
      }
    ],
    "concreteType": "UnifiedProductNode",
    "kind": "LinkedField",
    "name": "getUnifiedProduct",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "UnifiedProductImageGroupNodeConnection",
        "kind": "LinkedField",
        "name": "imageGroups",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UnifiedProductImageGroupNodeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UnifiedProductImageGroupNode",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "styleNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "colorCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "colorName",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UnifiedProductImageNodeConnection",
                    "kind": "LinkedField",
                    "name": "images",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UnifiedProductImageNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UnifiedProductImageNode",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "url",
                                "storageKey": null
                              },
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "sourceUrl",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hiResUrl",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "lastModified",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "image",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "thumbnailSmall",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "thumbnailMedium",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "sizeCategory",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "type",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "tags",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ImagesModalQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ImagesModalQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "5edb12b57f80645754720daceccd9acb",
    "id": null,
    "metadata": {},
    "name": "ImagesModalQuery",
    "operationKind": "query",
    "text": "query ImagesModalQuery(\n  $unifiedId: ID!\n) {\n  getUnifiedProduct(id: $unifiedId) {\n    id\n    imageGroups {\n      edges {\n        node {\n          id\n          styleNumber\n          colorCode\n          colorName\n          orientation\n          images {\n            edges {\n              node {\n                id\n                url\n                orientation\n                sourceUrl\n                hiResUrl\n                lastModified\n                image\n                thumbnailSmall\n                thumbnailMedium\n                sizeCategory\n                type\n                tags\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '864fa373aa85f65d12d249858f1c167b';
export default node;
