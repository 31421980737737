import React, {InputHTMLAttributes, RefObject, TextareaHTMLAttributes, useEffect, useRef} from 'react';
import {DelayInput} from "react-delay-input";
import addClassNames from '../classNameUtils';
import styles from './Input.module.scss';

const useAutoFocus = () => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return inputRef;
};

const useFocus = (isOnFocus: boolean) => {
  const inputRef = useRef<HTMLInputElement>(null);

  if (isOnFocus && inputRef && inputRef.current) {
    inputRef.current.focus();
  }
  return inputRef;
}

type ComponentExtraProps = {
  disabled?: boolean;
  invalid?: boolean;
  width?: string;
  delay?: number;
  minLength?: number;
  Tag?: React.FC | 'input' | 'textarea';
  autoFocus?: boolean;
  inputRef?: RefObject<HTMLDivElement>
}

export type InputProps = ComponentExtraProps & InputHTMLAttributes<HTMLInputElement>
type TextareaProps = ComponentExtraProps & TextareaHTMLAttributes<HTMLTextAreaElement>
type DefaultInputProps = {
  invalid?: boolean,
  setFocus?: boolean
} & InputHTMLAttributes<HTMLInputElement>

function getClassNames(
  Tag: React.FC | 'input' | 'textarea',
  disabled: boolean,
  invalid: boolean,
  className: string
) {
  return [
    {className: styles.input, condition: Tag === 'input' || Tag === 'textarea'},
    {className: styles.textarea, condition: Tag === 'textarea'},
    {className: styles.disabled, condition: disabled},
    {className: styles.invalid, condition: invalid},
    {className: className, condition: !!className},
  ];
}

export default function Input(props: InputProps): JSX.Element {
  const {
    disabled,
    invalid,
    delay = 0,
    minLength = 0,
    Tag = 'input',
    width,
    value,
    onChange,
    className,
    autoComplete = '',
    autoFocus = false,
    ...rest
  } = props;

  const classNames = getClassNames(Tag, !!disabled, !!invalid, className || '');
  const inputRef = useAutoFocus();

  return <DelayInput element={Tag}
                     value={value}
                     onChange={onChange}
                     disabled={disabled}
                     style={{width: width}}
                     className={addClassNames(classNames)}
                     minLength={minLength}
                     delayTimeout={delay}
                     autoComplete={autoComplete}
                     inputRef={autoFocus ? inputRef : undefined}
                     {...rest}/>
}


export function Textarea(props: TextareaProps) {
  const {
    disabled,
    invalid,
    delay = 0,
    minLength = 2,
    Tag = 'textarea',
    width,
    value,
    onChange,
    className,
    ...rest
  } = props;
  const classNames = getClassNames(Tag, !!disabled, !!invalid, className || '');

  return <DelayInput element={Tag}
                     value={value}
                     onChange={onChange}
                     disabled={disabled}
                     style={{width: width}}
                     className={addClassNames(classNames)}
                     minLength={minLength}
                     delayTimeout={delay}
                     {...rest}/>
}


export function DefaultInput(props: DefaultInputProps) {
  const {
    disabled,
    invalid,
    width,
    value,
    onChange,
    className,
    autoComplete = '',
    setFocus = false,
    ...rest
  } = props;
  const classNames = getClassNames('input', !!disabled, !!invalid, className || '');
  const ref = useFocus(setFocus);

  return <input value={value}
                onChange={onChange}
                disabled={disabled}
                style={{width: width}}
                className={addClassNames(classNames)}
                autoComplete={autoComplete}
                ref={ref}
                {...rest}/>
}
