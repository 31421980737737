import "animate.css/animate.min.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jsoneditor-react/es/editor.min.css';
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'

import "react-toastify/dist/ReactToastify.css";
import './ui-kit/src/assets/fontawesome-pro/css/all.css';
import './App.css';

// Containers
import {DefaultLayout} from './containers';
import './scss/style.scss'
import {EnvFetcher} from "./ui-kit/src/commons/http";
// Pages
import {Login, Page404, Page500} from './views/Pages';
import Forbidden from "./views/Pages/Forbidden/Forbidden";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

export default function App() {
  return (
    <EnvFetcher onLoad={(envConfig) => {
      if (!envConfig.sentry_dsn) {
        return;
      }
      Sentry.init({
        dsn: envConfig.sentry_dsn,
        environment: envConfig.environment_name,
        release: envConfig.release_name,
        integrations: [new BrowserTracing()]
      })
    }}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/login" component={Login}/>
            {/*<Route exact path="/register" component={Register} />*/}
            <Route exact path="/404" component={Page404}/>
            <Route exact path="/500" component={Page500}/>
            <Route exact path="/forbidden" component={Forbidden}/>
            <Route path="/" component={DefaultLayout}/>
          </Switch>
        </BrowserRouter>
      </QueryClientProvider>
    </EnvFetcher>
  );
}
